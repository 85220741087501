import React, { useState, useEffect } from 'react'
import peachIcon from "../../assets/img/pechicon2.svg"
import buttonClose from "../../assets/vector/closeButton.svg";
export default function MarketFilters({ openFilter, setOpenFilter, filterTractor, listItems, setListItemsFilter }) {
    const [filter, setFilter] = useState({
        sortBy: 0,
        peachBy: [0, -1],
        WPBy: [0, -1],
        TypeBy: [false, false],
        reset: () => {
            this.sortBy = 0;
            this.peachBy = [0, -1];
            this.WPBy = [0, -1];
            this.TypeBy = [false, false];
        }
    });

    const [filterWaifu, setFilterWaifu] = useState(-1);

    const handleFilter = () => {

        switch (filter.sortBy) {
            case 0:
                {
                    setListItemsFilter(listItems.sort((a, b) => a.sellPrice - b.sellPrice));
                    break;
                }
            case 1:
                {
                    setListItemsFilter(listItems.sort((a, b) => b.sellPrice - a.sellPrice));
                    break;
                }
            case 2:
                {
                    setListItemsFilter(listItems.sort((a, b) => a.sellPrice - b.sellPrice));
                    break;
                }
            case 3:
                {
                    setListItemsFilter(listItems.sort((a, b) => b.sellPrice - a.sellPrice));
                    break;
                }
            default:
                break
        }

        if (filter.peachBy[1] === -1) setListItemsFilter(listItems.filter(element => (Math.round((parseInt(element.sellPrice) / parseInt(1 + '0'.repeat(18)))) >= filter.peachBy[0])));
        else setListItemsFilter(listItems.filter(element => (Math.round((parseInt(element.sellPrice) / parseInt(1 + '0'.repeat(18)))) >= filter.peachBy[0] && Math.round((parseInt(element.sellPrice) / parseInt(1 + '0'.repeat(18)))) <= filter.peachBy[1])));

        if (!filterTractor) {
            if (filter.WPBy[1] === -1) setListItemsFilter(listItems.filter(element => element.waifuPower >= filter.WPBy[0]));
            else setListItemsFilter(listItems.filter(element => element.waifuPower >= filter.WPBy[0] && element.waifuPower <= filter.WPBy[1]));
        }
    }

    useEffect(() => {
        switch (filterWaifu) {
            case 0:
                setListItemsFilter(listItems.sort((a, b) => a.waifuPower - b.waifuPower));
                break;
            case 1:
                setListItemsFilter(listItems.sort((a, b) => b.waifuPower - a.waifuPower));
                break;
            case 2:
                setListItemsFilter(listItems.sort((a, b) => a.durability - b.durability));
                break;
            case 3:
                setListItemsFilter(listItems.sort((a, b) => b.durability - a.durability));
                break;
            case 4:
                setListItemsFilter(listItems.sort((a, b) => a.sellPrice - b.sellPrice));
                break;
            case 5:
                setListItemsFilter(listItems.sort((a, b) => b.sellPrice - a.sellPrice));
                break;

            default:
                break;
        }
    }, [filterWaifu]);

    return (
        <div className={`top-0 ${openFilter ? "left-0" : "left-[-100vw]"}  transition-all duration-500 ease-out z-[10] fixed w-full h-[100vh] bg-[#00000052] `}>

            <div className='ml-[0] lg:ml-[220px] w-full md:w-[20rem] bg-[#591830] fixed h-full rounded-md ' >


                <div onClick={() => setOpenFilter(false)}><img src={buttonClose} className="w-[30px] absolute right-2 top-[6rem] hover:cursor-pointer" alt="close-icon" /> </div>
                {filterTractor ? <>
                    <div className='rounded-sm px-5'>
                        <h4 className=' text-white font-bold text-xl  mb-[12px] pt-[6rem]'>Sort by</  h4>

                        <select className=' w-full h-[30px] font-medium text-lg rounded-sm' onChange={e => setFilter({ ...filter, sortBy: e.target.selectedIndex })}>
                            <option >Cheapest to most expensive</option>
                            <option>Most expensive to cheapest</option>
                            <option>Newest to oldest</option>
                            <option>Oldest to newest</option>

                        </select>
                    </div>

                    <div className='mt-[50px] px-5'>
                        <div className='flex items-center flex-row'>
                            <h4 className='text-white font-bold t text-xl '>Filter by $PEACH: </h4>
                            <img src={peachIcon} className='h-10 ml-2' alt="peach-icon" />
                        </div>
                        <input type='number' placeholder='0' className=' w-full  mr-4 rounded-md text-center font-semibold text-lg ' onChange={e => setFilter({ ...filter, peachBy: [parseInt(e.target.value) || 0, filter.peachBy[1]] })} />
                        <input type='number' placeholder='+999' className='  w-full mt-5 rounded-md text-center font-semibold text-lg ' onChange={e => setFilter({ ...filter, peachBy: [filter.peachBy[0], parseInt(e.target.value) || -1] })} />
                    </div>

                    {!filterTractor && <div className='mt-[50px]'>
                        <div className='flex flex-row mb-[12px]'>
                            <h4 className='text-white font-bold t text-xl ml-[32px] mb-[12px]'>Filter by WP: </h4>
                        </div>
                        <input type='number' placeholder='0' className='w-[170px] h-[36px] ml-[32px] mr-4 rounded-md text-center font-semibold text-lg ' onChange={e => setFilter({ ...filter, WPBy: [parseInt(e.target.value) || 0, filter.WPBy[1]] })} />
                        <input type='number' placeholder='+999' className='w-[170px] h-[36px] ml-[32px]  rounded-md mt-5  text-center font-semibold text-lg ' onChange={e => setFilter({ ...filter, WPBy: [filter.WPBy[0], parseInt(e.target.value) || -1] })} />
                    </div>}

                    <div className='flex justify-around flex-wrap mt-[90px]'>
                        <button className=' bg-[#B2B2B2] text-white  px-4 py-2 rounded-lg text-xl font-bold ' onClick={() => { setListItemsFilter(listItems); filter.reset(); setOpenFilter(false); }}>Reset</button>
                        <button className=' bg-[#AA2E74] text-white  px-4 py-2 rounded-lg  text-xl  font-bold ' onClick={() => {
                            handleFilter();
                            setOpenFilter(false);
                        }}>Apply</button>
                    </div>
                </>
                    : <div className="w-full flex flex-col items-center text-center justify-center mt-32">
                        <h3 className="text-xl font-bold text-white">WP</h3>
                        <div className="flex flex-row mb-4"><button onClick={() => setFilterWaifu(0)} className={` ${filterWaifu == 0 ? 'bg-darkPink text-white' : 'bg-white'} px-6 py-1 font-bold rounded-tl-xl rounded-bl-xl border-black border-r hover:bg-primary`}>Low to High</button><button onClick={() => setFilterWaifu(1)} className={` ${filterWaifu == 1 ? 'bg-darkPink text-white' : 'bg-white'} px-6 py-1 font-bold rounded-tr-xl rounded-br-xl border-black border-l hover:bg-primary`}>High to Low</button></div>
                        <h3 className="text-xl font-bold text-white">Durability</h3>
                        <div className="flex flex-row mb-4"><button onClick={() => setFilterWaifu(2)} className={` ${filterWaifu == 2 ? 'bg-darkPink text-white' : 'bg-white'} px-6 py-1 font-bold rounded-tl-xl rounded-bl-xl border-black border-r hover:bg-primary`}>Low to High</button><button onClick={() => setFilterWaifu(3)} className={` ${filterWaifu == 3 ? 'bg-darkPink text-white' : 'bg-white'} px-6 py-1 font-bold rounded-tr-xl rounded-br-xl border-black border-l hover:bg-primary`}>High to Low</button></div>
                        <h3 className="text-xl font-bold text-white">Price</h3>
                        <div className="flex flex-row mb-4"><button onClick={() => setFilterWaifu(4)} className={` ${filterWaifu == 4 ? 'bg-darkPink text-white' : 'bg-white'} px-6 py-1 font-bold rounded-tl-xl rounded-bl-xl border-black border-r hover:bg-primary`}>Low to High</button><button onClick={() => setFilterWaifu(5)} className={` ${filterWaifu == 5 ? 'bg-darkPink text-white' : 'bg-white'} px-6 py-1 font-bold rounded-tr-xl rounded-br-xl border-black border-l hover:bg-primary`}>High to Low</button></div>
                    </div>}
            </div>
        </div>
    )
}

/* Sell NTF Type
<div className='mt-[50px]'>
                    <h4 className='text-white font-bold t text-xl ml-[32px] mb-[12px]'>NFT sell type </h4>
                    <div className='flex items-center'>
                        <input type='checkbox' className='h-6 w-6 ml-[32px]  rounded-md' onChange={e => setFilter({...filter, TypeBy: [e.target.checked, filter.TypeBy[1]]})} /><label className='ml-[7px] text-white text-xl font-semibold'>Sale</label>
                        <input type='checkbox' className='h-6 w-6 ml-[151px]  rounded-md' onChange={e => setFilter({...filter, TypeBy: [filter.TypeBy[0], e.target.checked]})} /><label className='ml-[7px] text-white text-xl font-semibold'>Auction</label>
                    </div>
                </div>
*/