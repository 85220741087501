import React, { useState, useEffect, useContext } from 'react'
import { useForm } from "react-hook-form";
import start1 from "../../assets/vector/start1.svg"
import start2 from "../../assets/vector/start2.svg"
import start3 from "../../assets/vector/start3.svg"
import start4 from "../../assets/vector/start4.svg"
import start5 from "../../assets/vector/start5.svg"

import Loader from '../../components/elements/ModalX/loader';
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";
import TractorCardItem from '../elements/TractorCardItem';

import PaginationComponent from '../elements/PaginationComponent';
import UserContext from '../../context/userContext/UserContext';
import Loading from '../Loading/Loading';
import MarketFilters from '../MarketFilters/MarketFilters';
import contractAddress from "../../utils/Contract.json"
export default function MarketTractor() {

    const [listItemsFilter, setListItemsFilter] = useState(null)
    const [listItems, setListItems] = useState(-1)
    const [filterStart, setFilterStart] = useState("all")
    const [selectStar, setSelectStar] = useState(0)
    const [reloadData, setReloadData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [openFilter, setOpenFilter] = useState(false);
    const [filtered, filtered_action] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { topData, contract, account, chainId } = useContext(UserContext)
    const [allowance, setAllowance] = useState(false)




    useEffect(() => {
        let petitionsTractor = async () => {
            if (contract && account && !loading) {

                let flagItems = false
                let offset = 0
                let getAllItems = []
                while (flagItems === false) {


                    let result = await contract.contractTractor.methods.list(offset).call()
                    result.map((element, index) => {
                        if (element !== "0" && index !== 12) getAllItems.push(contract.contractTractor.methods.getTractor(element).call())
                        if (element === "0") flagItems = true
                    })
                    offset = result[12]

                }



                Promise.all(getAllItems).then(result => {
                    const listAllTractors = result.reverse().map(element => ({ adn: '0'.repeat(61 - element[1].length) + element[1], ...element }))
                    setListItems(listAllTractors)
                    setListItemsFilter(listAllTractors)
                }).catch(err => {

                    setListItems([])
                    setListItemsFilter([])
                })
            }

        }


        petitionsTractor()

    }, [contract, account, loading, chainId])


    const handleFilterStart = (value) => {
        filtered_action(true)
        if (value === "all") return setListItemsFilter(listItems)
        setListItemsFilter(listItems.filter(element => element.level === value))
    }

    const HaventTractors = () => (
        <div className="grid grid-rows-mint justify-center text-center">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 mx-auto self-center"></img>
            <span className="font-bold text-base text-black">It Seems that You Don't Have Any Tractor at this moment!</span>
            <span className="text-sm text-black">After you have minted your Tractors will appear here</span>
        </div>
    );

    const stars_list = ['#', start1, start2, start3, start4, start5];
    const Not_Filter = () => (
        <div className="grid grid-rows-mint justify-center text-center ">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 mx-auto self-center"></img>
            <div className="font-bold text-lg text-black flex justify-center items-center">
                <span className="mr-2">There are no Tractors {(selectStar !== 0) && 'of'}</span>
                {(selectStar !== 0) && <img className='w-10' src={stars_list[selectStar]} alt="star" />}
            </div>
            <span className=" text-base text-black">After you have minted your Farmer Tractors will appear here</span>
        </div>
    );



    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])




    const buyTractor = (element) => {
        if (contract) {
            setLoading(true)

            contract.contractDWGameTractor.methods.buy(element.id).send({ from: account, value: parseInt(element.sellPrice) + Math.pow(10, 13) }).then(result => setLoading(false)).catch(err => setLoading(false));
        }
    }

    return (
        <div className="p-5 px-10 relative ">

            {listItems !== -1 && <MarketFilters filterTractor={true} openFilter={openFilter} setOpenFilter={setOpenFilter} listItems={listItems} setListItemsFilter={setListItemsFilter} />}
            <Loading modal={loading} setModal={setLoading} />

            <section className="flex flex-wrap flex-col justify-center items-center md:flex-row md:justify-between ">

                <div className='p-3 bg-[#F0C1DC]  rounded-md ' >
                    <button onClick={() => { setOpenFilter(true) }} className='bg-[#AA2E74] px-2 py-1 mx-auto text-white font-bold text-md rounded-md'>
                        Sort and Filters
                    </button>
                </div>

                <div>
                    <h3 className="pb-2 text-xl mt-5 md:mt-0"> <strong>Filter by Tractor Rarity:</strong>  </h3>
                    <div className="flex justify-center items-center ">
                        <button onClick={() => { handleFilterStart("all"); setSelectStar(0) }} className={`${selectStar == 0 ? "bg-primaryDark" : "bg-background"} w-10 rounded-md text-white h-10  active:scale-90`}>All</button>
                        <img onClick={() => { handleFilterStart("1"); setSelectStar(1) }} src={start1} alt="start-1" className={`${selectStar == 1 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                        <img onClick={() => { handleFilterStart("2"); setSelectStar(2) }} src={start2} alt="start-2" className={`${selectStar == 2 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                        <img onClick={() => { handleFilterStart("3"); setSelectStar(3) }} src={start3} alt="start-3" className={`${selectStar == 3 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                        <img onClick={() => { handleFilterStart("4"); setSelectStar(4) }} src={start4} alt="start-4" className={`${selectStar == 4 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                        <img onClick={() => { handleFilterStart("5"); setSelectStar(5) }} src={start5} alt="start-5" className={`${selectStar == 5 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                    </div>
                </div>
            </section>

            {!listItemsFilter
                ? <div className="h-80 flex justify-center items-center"><Loader /></div>
                : <section className=" pt-20 pb-10"> {listItemsFilter.length > 0 ? <PaginationComponent setReloadData={setReloadData} buyTractor={buyTractor} item={"tractorMarket"} listItems={listItemsFilter} paginationCant={12} balance={topData?.balancePeach} /> : <> {filtered ? <Not_Filter /> : <HaventTractors />} </>}  </section>
            }
        </div>
    )
}
