import * as React from "react";
import Layout from "../components/layouts/Layout";
import backgroundHex from "../assets/img/backgroundSections/background.webp";
import MarketTractorComponent from "../components/MarketTractor/MarketTractor";


// markup
const MarketTractor = () => {

  return (
    <Layout background={backgroundHex}>
      <MarketTractorComponent />
    </Layout>
  )
}

export default MarketTractor
